import React, { useCallback, useEffect, useMemo } from "react";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Button,
  Card,
  ComboBox,
  DateField,
  dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Icon,
  Link,
  Loader,
  Text,
  TextField,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { useEvent, useForm } from "@adaptive/design-system/hooks";
import {
  formatCurrency,
  formatDate,
  is,
  isEqual,
} from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import {
  CUSTOMER_ACCOUNT_TYPE,
  EM_DASH,
  GET_CANCEL_CONFIRMATION_AMOUNT_TITLE,
  GET_PAY_NOW_SCHEDULED_CONFIRMATION_AMOUNT_TITLE,
  HUMAN_READABLE_PAYMENT_METHOD,
  PAYMENT_METHOD,
  PROCESS_PREREQUISITE_TYPE,
  STRINGS,
} from "@bill-payment/constants";
import {
  useBillPaymentV2,
  useCancelBillPaymentV2Mutation,
  useVoidBillPaymentV2Mutation,
} from "@bill-payment/hooks";
import { type PaymentComment } from "@bill-payment/types";
import { CardTransactionField } from "@card-feed/components";
import type { LinkedCost } from "@card-feed/types";
import { Comments, type OnAddCommentHandler } from "@components/comments";
import { ErrorAlert } from "@components/common/error-alert";
import { NotFound } from "@components/not-found";
import { useCurrentClientFromRealm } from "@hooks/use-current-client-from-realm";
import { useObjectInternalVersion } from "@hooks/use-object-internal-version";
import { BillLienWaiverField } from "@lien-waiver/components";
import { LIEN_WAIVER_STATUS } from "@lien-waiver/constants";
import { usePatchLienWaiverRequestMutation } from "@lien-waiver/hooks";
import { type LienWaiver } from "@lien-waiver/types";
import { OBJECT_ORIGIN } from "@shared/constants";
import {
  FIELDS_TO_RELOAD_AFTER_PAYMENT_ACTION,
  PAYMENT_STATUS,
} from "@src/bills/constants";
import { type PaymentStatusKey } from "@src/bills/types";
import { selectStaticBill } from "@src/bills/utils";
import { refetchCurrentBill } from "@store/billSlice";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { BasePermissions, useClientSettings, useUserInfo } from "@store/user";
import * as analytics from "@utils/analytics";
import { formatCard } from "@utils/format-card";
import { useVendorAction } from "@vendors/hooks";
import type { Stage } from "@vendors/types";
import type { AnyAction } from "redux";
import { z } from "zod";

import { billPaymentApi, usePayNowScheduledPaymentV2Mutation } from "../api";
import { billPaymentLienWaiverSchema } from "../api/schemas";
import { LinkedTransactionsTable } from "../components";
import { parseLienWaiverTemplate } from "../utils/parse-lien-waiver-template";

import { BillPaymentStatusTag } from "./bill-payment-status-tag";

const schema = z.object({
  payFromAccount: z.string().nullish(),
  payToAccount: z.string().nullish(),
  paymentMethod: z.object({ value: z.string(), label: z.string() }).optional(),
  refNumber: z.string(),
  debitDate: z.date().nullish(),
  deliveryDate: z.object({
    from: z.date().nullish(),
    to: z.date().nullish(),
  }),
  lienWaivers: z.array(billPaymentLienWaiverSchema).nullish(),
});

type BillPaymentFormFields = z.infer<typeof schema>;

type BillPaymentFormFieldKeys = keyof BillPaymentFormFields;

const DAYS_THRESHOLD = { daysBefore: 30, daysAfter: 30 };

const SELECT_CARD = {
  title: STRINGS.SELECT_TRANSACTION_TITLE,
};

const BillPaymentFormTitle = ({
  isLoading,
  billPaymentDocNumber,
}: {
  isLoading: boolean;
  billPaymentDocNumber?: string | null;
}) => {
  const paymentRefTitle = useMemo(() => {
    return billPaymentDocNumber ? `#${billPaymentDocNumber}` : "";
  }, [billPaymentDocNumber]);

  return (
    <>
      {isLoading && STRINGS.LOADING_TITLE}
      {!isLoading && `Payment ${paymentRefTitle}`}
    </>
  );
};

export const BillPaymentForm = ({
  billPaymentId,
  onClose,
}: {
  billPaymentId: string;
  onClose: () => void;
}) => {
  const { hasPermission, canManageLienWaiverRequest } = useUserInfo();
  const dispatch = useAppDispatch();

  const [triggerCancelBillPaymentV2, { isLoading: isLoadingCancelPayment }] =
    useCancelBillPaymentV2Mutation();
  const [
    triggerPayNowScheduledPayment,
    { isLoading: isLoadingPayNowScheduledPayment },
  ] = usePayNowScheduledPaymentV2Mutation();
  const [triggerVoidBillPaymentV2, { isLoading: isLoadingVoidPayment }] =
    useVoidBillPaymentV2Mutation();
  const rawBill = useAppSelector(selectStaticBill, isEqual);
  const billInternalVersion = useObjectInternalVersion(rawBill);

  const {
    data: billPayment,
    isLoading: isBillPaymentLoading,
    isFetching: isBillPaymentFetching,
    refetch: refetchBillPayment,
    isError: isBillPaymentError,
  } = useBillPaymentV2({
    billPaymentId,
  });

  useCurrentClientFromRealm(billPayment?.realm);

  const { showVendorById } = useVendorAction();

  const curriedOpenVendor = useCallback(
    (stage: Stage) => () => {
      if (billPayment?.vendor?.id) {
        showVendorById(billPayment.vendor.id.toString(), stage);
      }
    },
    [billPayment?.vendor?.id, showVendorById]
  );

  const commentsSelector = useMemo(
    () =>
      billPayment?.timelineEvents
        ? {
            url: billPayment?.url,
            comments: billPayment?.timelineEvents,
          }
        : undefined,
    [billPayment?.url, billPayment?.timelineEvents]
  );

  const canPayBill = hasPermission(BasePermissions.PAY_BILLS);
  const isPaid = billPayment?.status === PAYMENT_STATUS.PAID;
  const isQBPayment = billPayment?.createdIn === OBJECT_ORIGIN.QUICKBOOKS;

  const isLoadingInitialData = isBillPaymentLoading;

  const canCancelPayment = useMemo(() => {
    return (
      canPayBill &&
      (billPayment?.status === PAYMENT_STATUS.ACH_REQUESTED ||
        billPayment?.status === PAYMENT_STATUS.PENDING_PREREQUISITES)
    );
  }, [billPayment?.status, canPayBill]);

  const { processOnScheduledDateEnabled } = useClientSettings();

  const canPayNowScheduledPayment = useMemo(() => {
    return (
      processOnScheduledDateEnabled &&
      billPayment?.processPrerequisites?.some(
        (prerequisite) =>
          prerequisite.type === PROCESS_PREREQUISITE_TYPE.SCHEDULED_DATE &&
          !prerequisite.isSatisfied
      )
    );
  }, [billPayment?.processPrerequisites, processOnScheduledDateEnabled]);

  const canVoidPayment = useMemo(() => {
    return (
      canPayBill &&
      billPayment?.status === PAYMENT_STATUS.PAID &&
      billPayment?.paymentMethod === PAYMENT_METHOD.MARK_AS_PAID
    );
  }, [billPayment?.paymentMethod, billPayment?.status, canPayBill]);

  const onCancelOrVoidPayment = useCallback(
    async (billPaymentId: string) => {
      if (!billPaymentId || (!canCancelPayment && !canVoidPayment)) return;
      const handler = async () => {
        try {
          if (canCancelPayment) {
            await triggerCancelBillPaymentV2({
              billPaymentId,
            }).unwrap();
            analytics.track("billPaymentCancel");
            toast.success("Payment canceled");
          } else {
            await triggerVoidBillPaymentV2({
              billPaymentId,
            }).unwrap();
            toast.success("Payment voided");
            analytics.track("billPaymentVoid");
          }
          dispatch(refetchCurrentBill(FIELDS_TO_RELOAD_AFTER_PAYMENT_ACTION));
          refetchBillPayment();
          onClose();
        } catch (e) {
          if (!handleErrors(e)) throw e;
        }
      };

      dialog.confirmation({
        title: (
          <>
            {STRINGS.ARE_YOU_SURE_TITLE_PREFIX} <br />
            {isPaid
              ? STRINGS.VOID_TITLE.toLowerCase()
              : STRINGS.CANCEL_TITLE.toLowerCase()}{" "}
            {billPayment &&
              GET_CANCEL_CONFIRMATION_AMOUNT_TITLE(billPayment.appliedAmount)}
          </>
        ),
        message: (
          <>
            {STRINGS.ARE_YOU_SURE_SUBTITLE_PREFIX} <br />
            {STRINGS.ARE_YOU_SURE_SUBTITLE_SUFFIX}
          </>
        ),
        action: {
          primary: {
            children: `${
              canCancelPayment ? STRINGS.CANCEL_TITLE : STRINGS.VOID_TITLE
            } ${STRINGS.PAYMENT_TITLE}`,
            color: "error",
            onClick: handler,
            "data-testid": "confirm-cancel-or-void-payment",
          },
          secondary: {
            children: STRINGS.CLOSE_TITLE,
          },
        },
      });
    },
    [
      billPayment,
      canCancelPayment,
      canVoidPayment,
      dispatch,
      isPaid,
      onClose,
      refetchBillPayment,
      triggerCancelBillPaymentV2,
      triggerVoidBillPaymentV2,
    ]
  );

  const onPayNowScheduledPayment = useCallback(
    async (billPaymentId: string) => {
      if (!billPaymentId || !canPayNowScheduledPayment) return;
      const handler = async () => {
        try {
          await triggerPayNowScheduledPayment({
            billPaymentId,
          }).unwrap();
          analytics.track("billPaymentPayNowScheduled");
          toast.success("Paid successfully");
          dispatch(refetchCurrentBill(FIELDS_TO_RELOAD_AFTER_PAYMENT_ACTION));
          refetchBillPayment();
          onClose();
        } catch (e) {
          if (!handleErrors(e)) throw e;
        }
      };

      dialog.confirmation({
        title: (
          <>
            {STRINGS.ARE_YOU_SURE_TITLE_PREFIX} <br />
            {STRINGS.PAY_NOW_TITLE.toLowerCase()}{" "}
            {billPayment &&
              GET_PAY_NOW_SCHEDULED_CONFIRMATION_AMOUNT_TITLE(
                billPayment.appliedAmount
              )}
          </>
        ),
        message: STRINGS.ARE_YOU_SURE_SUBTITLE_PREFIX,
        action: {
          primary: {
            children: STRINGS.PAY_NOW_TITLE,
            onClick: handler,
            "data-testid": "confirm-pay-now-scheduled-payment",
          },
          secondary: {
            children: STRINGS.CLOSE_TITLE,
          },
        },
      });
    },
    [
      billPayment,
      canPayNowScheduledPayment,
      dispatch,
      onClose,
      refetchBillPayment,
      triggerPayNowScheduledPayment,
    ]
  );

  const onDownloadPrintedCheck = useCallback(
    () => window.open(`/api/v2/billpayments/${billPaymentId}/check/`, "_blank"),
    [billPaymentId]
  );

  const initialValues = useMemo(() => {
    if (!billPayment) {
      return {
        paymentMethod: undefined,
        payFromAccount: "",
        refNumber: "",
        debitDate: undefined,
        payToAccount: "",
        deliveryDate: {
          from: undefined,
          to: undefined,
        },
        lienWaiver: null,
        cardTransaction: null,
      };
    }

    return {
      paymentMethod: {
        value: billPayment.paymentMethod || "",
        label:
          billPayment.createdIn === OBJECT_ORIGIN.QUICKBOOKS
            ? STRINGS.PAID_IN_QUICKBOOKS
            : HUMAN_READABLE_PAYMENT_METHOD[billPayment.paymentMethod] || "",
      },
      payFromAccount:
        billPayment.customerCard?.url ||
        billPayment.customerBankAccount?.url ||
        billPayment.customerPaymentAccount?.url ||
        "",
      payToAccount: billPayment.vendorBankAccount?.url || "",
      refNumber: billPayment?.docNumber || "—",
      debitDate:
        (billPayment?.paymentSchedule?.processedOn &&
          new Date(billPayment?.paymentSchedule?.processedOn)) ||
        undefined,
      deliveryDate: {
        from:
          (billPayment?.paymentSchedule?.expectedDeliveryAfter &&
            new Date(billPayment?.paymentSchedule?.expectedDeliveryAfter)) ||
          undefined,
        to:
          (billPayment?.paymentSchedule?.expectedDeliveryBefore &&
            new Date(billPayment?.paymentSchedule?.expectedDeliveryBefore)) ||
          undefined,
      },
      lienWaivers:
        billPayment?.lienWaivers?.map((lienWaiver) => ({
          ...lienWaiver,
          lienWaiverTemplate: parseLienWaiverTemplate(lienWaiver),
        })) || [],
      cardTransaction: billPayment?.appliedCardTransaction || null,
    };
  }, [billPayment]);

  const {
    values: formValues,
    setValue: setFormValue,
    reset: resetForm,
    ...form
  } = useForm<BillPaymentFormFields>({
    initialValues,
    schema,
  });

  const isLoadingAction =
    form.isSubmitting ||
    isLoadingCancelPayment ||
    isLoadingVoidPayment ||
    isLoadingPayNowScheduledPayment;

  const isPaymentAwaitingACHCollection = useMemo(() => {
    return (
      billPayment?.paymentMethod === PAYMENT_METHOD.ACH &&
      !billPayment?.vendorBankAccount
    );
  }, [billPayment?.paymentMethod, billPayment?.vendorBankAccount]);

  const isPaymentAwaitingLienWaiverSignature = useMemo(() => {
    return (
      billPayment?.status === PAYMENT_STATUS.PENDING_PREREQUISITES &&
      billPayment?.processPrerequisites?.[0]?.type === "lien_waiver_signature"
    );
  }, [billPayment?.processPrerequisites, billPayment?.status]);

  const disabledFields: Partial<Record<BillPaymentFormFieldKeys, boolean>> =
    useMemo(() => {
      return {
        refNumber: true,
        paymentMethod: true,
        payFromAccount: true,
        debitDate: true,
        payToAccount: true,
        deliveryDate: true,
      };
    }, []);

  const debitDateHelperMessage = useMemo(() => {
    if (isPaymentAwaitingACHCollection) {
      return STRINGS.PAY_ACH_COLLECTION_TITLE;
    }
    if (isPaymentAwaitingLienWaiverSignature) {
      return STRINGS.PAY_LIEN_WAIVER_TITLE;
    }
    return undefined;
  }, [isPaymentAwaitingACHCollection, isPaymentAwaitingLienWaiverSignature]);

  const showPayTo = formValues.paymentMethod?.value === PAYMENT_METHOD.ACH;

  const showCreditCardTransactionField =
    (billPayment?.appliedCardTransaction ||
      billPayment?.hasUnmatchedCardTransactions) &&
    billPayment?.status !== PAYMENT_STATUS.CANCELLED;

  const showLienWaiverField =
    !isLoadingInitialData &&
    canManageLienWaiverRequest &&
    billPayment?.vendor &&
    (billPayment?.lienWaivers?.length || 0) > 0;

  const [patchLienWaiverRequest] = usePatchLienWaiverRequestMutation();

  const curriedOnChangeLienWaiverTemplate = useCallback(
    (lienWaiverId: string) => async (value: string) => {
      const index = formValues?.lienWaivers?.findIndex(
        (lienWaiver) => lienWaiver?.id === lienWaiverId
      );

      if (is.number(index) && index >= 0) {
        const isLienWaiverNotRequired =
          !value || value === LIEN_WAIVER_STATUS.NOT_REQUIRED;

        const payload = {
          status: isLienWaiverNotRequired
            ? value
            : LIEN_WAIVER_STATUS.NOT_REQUESTED,
          lienWaiverTemplate: isLienWaiverNotRequired ? null : value,
        };

        setFormValue(`lienWaivers.${index}`, {
          ...formValues?.lienWaivers?.[index],
          ...payload,
        });

        const lienWaiverId = formValues?.lienWaivers?.[index].id;

        if (
          isLienWaiverNotRequired &&
          billPayment?.lienWaivers?.[index]?.status !==
            LIEN_WAIVER_STATUS.NOT_REQUIRED &&
          lienWaiverId
        ) {
          try {
            await patchLienWaiverRequest({
              id: lienWaiverId,
              status: LIEN_WAIVER_STATUS.NOT_REQUIRED,
              lienWaiverTemplateId: undefined,
              billPaymentId: billPaymentId,
            }).unwrap();

            dispatch(refetchCurrentBill(FIELDS_TO_RELOAD_AFTER_PAYMENT_ACTION));
            toast.success("Lien waiver marked as not required");
          } catch (e) {
            toast.error("Failed to mark lien waiver as not required");
            handleErrors(e);
          }
        }
      }
    },
    [
      billPayment?.lienWaivers,
      billPaymentId,
      dispatch,
      formValues?.lienWaivers,
      patchLienWaiverRequest,
      setFormValue,
    ]
  );

  const costForCardTransaction = useMemo(() => {
    return billPayment?.vendor &&
      billPayment?.vendor?.email &&
      formValues?.debitDate
      ? ({
          url: billPayment.url.replace("v2/", ""),
          vendor: {
            displayName: `${billPayment?.vendor.displayName}`,
            email: billPayment?.vendor.email,
            url: billPayment?.vendor.url,
          },
          paymentAccount: billPayment?.customerPaymentAccount,
          date: formValues?.debitDate,
          cardTransaction: billPayment?.appliedCardTransaction,
        } as LinkedCost)
      : undefined;
  }, [
    billPayment?.appliedCardTransaction,
    billPayment?.customerPaymentAccount,
    billPayment?.url,
    billPayment?.vendor,
    formValues?.debitDate,
  ]);

  const onAddComment = useEvent<OnAddCommentHandler>(
    ({ user, text, id, url, parentCommentUrl, files }) => {
      if (!billPayment) throw new Error("Invalid payment");

      const action = billPaymentApi.util.updateQueryData(
        "getBillPaymentV2",
        { billPaymentId: billPayment.id },
        (payment) => {
          const newComment: PaymentComment = {
            text,
            author: user,
            id: id || "",
            url,
            files,
            createdAt: new Date(),
            timelineEventType: "COMMENT",
            attachable: "",
          };
          if (parentCommentUrl) {
            payment.timelineEvents = payment.timelineEvents?.map((comment) =>
              comment.url === parentCommentUrl
                ? {
                    ...comment,
                    replies: [...(comment.replies || []), newComment],
                  }
                : comment
            );
          } else {
            payment.timelineEvents?.push({ ...newComment, replies: [] });
          }
        }
      ) as unknown as AnyAction;

      dispatch(action);
    }
  );

  const onChangeTransaction = useCallback(async () => {
    dispatch(
      refetchCurrentBill(
        FIELDS_TO_RELOAD_AFTER_PAYMENT_ACTION
      ) as unknown as AnyAction
    );
    refetchBillPayment();
  }, [dispatch, refetchBillPayment]);

  useEffect(() => {
    resetForm(initialValues);
  }, [initialValues, resetForm]);

  const getLastEventByType = useCallback(
    (type: string) => {
      const events =
        billPayment?.timelineEvents?.filter(
          (event) => event.timelineEventType === type
        ) || [];
      return events[events.length - 1];
    },
    [billPayment?.timelineEvents]
  );

  const voidedPayment = useMemo(() => {
    const voidedPaymentEvent = getLastEventByType("PAYMENT_VOIDED");
    const cancelledPaymentEvent = getLastEventByType("PAYMENT_CANCELLED");
    const event = voidedPaymentEvent || cancelledPaymentEvent;

    if (!event) return;

    return {
      author: event?.author?.fullName,
      createdAt: event?.createdAt
        ? formatDate(new Date(event?.createdAt), "MM/dd/yyyy 'at' hh:mma")
        : "",
      actionTitle: cancelledPaymentEvent
        ? STRINGS.PAYMENT_CANCELLED_TITLE
        : STRINGS.PAYMENT_VOIDED_TITLE,
    };
  }, [getLastEventByType]);

  const paymentFromAccountOptions = useMemo(() => {
    if (billPayment?.customerBankAccount || billPayment?.customerCard) {
      const name =
        billPayment?.customerCard?.fullName ||
        billPayment?.customerBankAccount?.name ||
        billPayment?.customerPaymentAccount?.displayName;

      const paymentAccountName =
        billPayment?.customerBankAccount?.paymentAccount?.displayName ||
        billPayment?.customerPaymentAccount?.displayName ||
        "";

      const mask =
        billPayment?.customerCard?.mask ||
        billPayment?.customerBankAccount?.mask ||
        "";
      return [
        {
          label: `${formatCard({
            name,
            mask: name?.includes(mask) ? undefined : mask,
          })}${paymentAccountName ? " - " : ""}${paymentAccountName}`,
          value:
            billPayment.customerCard?.url ||
            billPayment.customerBankAccount?.url ||
            "",
        },
      ];
    }

    if (billPayment?.customerPaymentAccount) {
      return [
        {
          label: billPayment.customerPaymentAccount.displayName || EM_DASH,
          value: billPayment.customerPaymentAccount.url,
        },
      ];
    }

    return [];
  }, [
    billPayment?.customerBankAccount,
    billPayment?.customerCard,
    billPayment?.customerPaymentAccount,
  ]);

  const payToAccountOptions = useMemo(() => {
    return billPayment?.vendorBankAccount
      ? [
          {
            label: `${
              STRINGS.ROUTING_NUMBER_TITLE
            } ${billPayment.vendorBankAccount.routingNumber.replaceAll(
              "*",
              "•"
            )} - ${
              STRINGS.ACCOUNT_NUMBER_TITLE
            } ${billPayment.vendorBankAccount.accountNumber.replaceAll(
              "*",
              "•"
            )}`,
            value: billPayment.vendorBankAccount.url,
          },
        ]
      : [];
  }, [billPayment?.vendorBankAccount]);

  const isCancelledVoidedOrFailed =
    billPayment?.status === PAYMENT_STATUS.CANCELLED ||
    billPayment?.status === PAYMENT_STATUS.FAILED;

  const cancelOrVoidButtonDisabled = useMemo<boolean | string>(() => {
    if (isLoadingInitialData || isLoadingAction || !canPayBill) {
      return true;
    }
    if (isQBPayment) {
      return "This payment was created in QuickBooks. You need to void it in QuickBooks.";
    }
    return false;
  }, [canPayBill, isQBPayment, isLoadingAction, isLoadingInitialData]);

  if (!isLoadingInitialData && !billPayment && isBillPaymentError) {
    return <NotFound to="/bill-payments" resource="payment" />;
  }

  return (
    <>
      {isLoadingAction && <Loader position="absolute" />}
      <DialogHeader>
        <Flex justify="space-between" align="center">
          <Flex gap="md" justify="flex-start" direction="column">
            <BillPaymentFormTitle
              isLoading={isLoadingInitialData}
              billPaymentDocNumber={billPayment?.docNumber}
            />
            {billPayment && (
              <Text size="md">
                ${formatCurrency(billPayment?.appliedAmount)}
              </Text>
            )}
          </Flex>
          {billPayment && (
            <BillPaymentStatusTag
              status={billPayment.status as PaymentStatusKey}
              paymentMethod={billPayment.paymentMethod}
              isVoided={billPayment.isVoided}
              isQBPayment={isQBPayment}
            />
          )}
        </Flex>
      </DialogHeader>
      <DialogContent>
        <Flex width="full" direction="column" gap="4xl">
          <Flex
            width="full"
            justify="flex-start"
            direction="column"
            gap="4xl"
            grow
            as="form"
            {...form.props}
          >
            {!!billPayment?.syncErrors?.length && (
              <ErrorAlert
                data={billPayment?.syncErrors}
                objectType="BillPayment"
              />
            )}
            {(isLoadingInitialData ||
              !billPayment ||
              isBillPaymentFetching) && <Loader />}
            {!isLoadingInitialData && billPayment && (
              <Flex width="full" justify="flex-start" direction="column">
                <Flex gap="lg" direction="column">
                  {billPayment?.isVoided && voidedPayment && (
                    <Alert variant="error">
                      <AlertTitle>
                        {voidedPayment.actionTitle} {voidedPayment.author}
                      </AlertTitle>
                      <AlertContent>{voidedPayment.createdAt}</AlertContent>
                    </Alert>
                  )}
                  <Text as="h2" size="xl" weight="bold">
                    {STRINGS.PAYMENTS_DETAILS_TITLE}
                  </Text>
                  <Card
                    as={Flex}
                    direction="column"
                    width="full"
                    gap={{ mobile: "none", tablet: "lg" }}
                  >
                    <Flex
                      gap={{ mobile: "none", tablet: "xl" }}
                      direction={{ mobile: "column", tablet: "row" }}
                      width="full"
                    >
                      <TextField
                        label={STRINGS.REF_TITLE}
                        disabled={disabledFields["refNumber"]}
                        {...form.register("refNumber")}
                      />
                      <ComboBox
                        disabled={disabledFields["paymentMethod"]}
                        label={STRINGS.PAYMENT_METHOD_TITLE}
                        {...form.register("paymentMethod")}
                        value={formValues.paymentMethod}
                        errorMessage={null}
                      />
                    </Flex>
                    <Flex
                      gap={{ mobile: "none", tablet: "xl" }}
                      direction={{ mobile: "column", tablet: "row" }}
                      width="full"
                    >
                      <Flex direction="column" width="full">
                        <ComboBox
                          disabled={disabledFields["payFromAccount"]}
                          data={paymentFromAccountOptions}
                          prefix={
                            billPayment?.customerAccountType ===
                            CUSTOMER_ACCOUNT_TYPE.CREDIT_CARD ? (
                              <Icon name="credit-card" />
                            ) : (
                              <Icon name="bank" />
                            )
                          }
                          placeholder=""
                          label={
                            isPaid
                              ? STRINGS.PAID_FROM_TITLE
                              : STRINGS.PAY_FROM_TITLE
                          }
                          {...form.register("payFromAccount")}
                        />
                      </Flex>
                      <Flex direction="column" width="full">
                        <DateField
                          label={STRINGS.VENDOR_DEBIT_DATE_TITLE}
                          disabled={disabledFields["debitDate"]}
                          fromDate={new Date()}
                          helperMessage={debitDateHelperMessage}
                          {...form.register({
                            name: "debitDate",
                            type: "date",
                          })}
                        />
                      </Flex>
                    </Flex>
                    {formValues.paymentMethod?.value ===
                      PAYMENT_METHOD.VIRTUAL_EMAIL_CHECK && (
                      <Flex gap={{ mobile: "none", tablet: "xl" }}>
                        <Flex direction="column" gap="xs">
                          <TextField
                            disabled
                            width="full"
                            label={STRINGS.VENDOR_EMAIL_TITLE}
                            messageVariant="hidden"
                            value={billPayment?.vendorEmail || ""}
                          />
                          <Text size="xs" color="neutral-500">
                            {STRINGS.CHANGE_EMAIL_TITLE}{" "}
                            <Link
                              variant="success"
                              size="xs"
                              onClick={curriedOpenVendor("info")}
                            >
                              {STRINGS.CHANGE_IT_HERE_TITLE}
                            </Link>
                          </Text>
                        </Flex>
                      </Flex>
                    )}
                    {formValues.paymentMethod?.value ===
                      PAYMENT_METHOD.MAIL_CHECK && (
                      <Flex gap={{ mobile: "none", tablet: "xl" }}>
                        <Flex direction="column" gap="xs" width="full">
                          <TextField
                            disabled
                            width="full"
                            label={STRINGS.VENDOR_ADDRESS_TITLE}
                            messageVariant="hidden"
                            value={billPayment?.vendorAddress || ""}
                          />
                          <Text size="xs" color="neutral-500">
                            {STRINGS.CHANGE_ADDRESS_TITLE}{" "}
                            <Link
                              variant="success"
                              size="xs"
                              onClick={curriedOpenVendor("info")}
                            >
                              {STRINGS.CHANGE_IT_HERE_TITLE}
                            </Link>
                          </Text>
                        </Flex>
                      </Flex>
                    )}
                    {formValues.paymentMethod?.value ===
                      PAYMENT_METHOD.VIRTUAL_SMS_CHECK && (
                      <Flex gap={{ mobile: "none", tablet: "xl" }}>
                        <Flex direction="column" gap="xs">
                          <TextField
                            disabled
                            width="full"
                            label={STRINGS.VENDOR_PHONE_NUMBER_TITLE}
                            messageVariant="hidden"
                            value={billPayment?.vendorPhoneNumber || ""}
                          />
                          <Text size="xs" color="neutral-500">
                            {STRINGS.CHANGE_PHONE_NUMBER_TITLE}{" "}
                            <Link
                              variant="success"
                              size="xs"
                              onClick={curriedOpenVendor("info")}
                            >
                              {STRINGS.CHANGE_IT_HERE_TITLE}
                            </Link>
                          </Text>
                        </Flex>
                      </Flex>
                    )}
                    {showPayTo && (
                      <Flex
                        gap={{ mobile: "none", tablet: "xl" }}
                        direction={{ mobile: "column", tablet: "row" }}
                        width="full"
                      >
                        <Flex direction="column" gap="sm" width="full">
                          <ComboBox
                            disabled={disabledFields["payToAccount"]}
                            label={STRINGS.PAY_TO_TITLE}
                            placeholder=""
                            data={payToAccountOptions}
                            helperMessage={
                              isPaymentAwaitingACHCollection &&
                              billPayment?.vendor?.latestAchRequest
                                ?.createdAt &&
                              `${STRINGS.REQUESTED_ON_TITLE} ${formatDate(
                                billPayment.vendor.latestAchRequest.createdAt
                              )}`
                            }
                            {...form.register("payToAccount")}
                          />
                          {isPaymentAwaitingACHCollection && (
                            <Text size="xs" color="neutral-500">
                              {STRINGS.ACH_DETAILS_TITLE}{" "}
                              <Link
                                variant="success"
                                size="xs"
                                onClick={curriedOpenVendor("payments")}
                              >
                                {STRINGS.ADD_IT_HERE_TITLE}
                              </Link>
                            </Text>
                          )}
                        </Flex>
                        <Flex direction="column" width="full">
                          <DateField
                            label="Delivery date"
                            mode="range"
                            disabled={disabledFields["deliveryDate"]}
                            {...form.register({
                              name: "deliveryDate",
                              type: "range-date",
                            })}
                          />
                        </Flex>
                      </Flex>
                    )}
                    {showCreditCardTransactionField &&
                      costForCardTransaction && (
                        <Flex gap={{ mobile: "none", tablet: "xl" }}>
                          <CardTransactionField
                            label={STRINGS.CARD_TRANSACTION_TITLE}
                            filtersAsFixed
                            valueVariant="long"
                            suffix={<Icon size="md" name="merge" />}
                            placeholder={STRINGS.MATCH_TRANSACTION_TITLE}
                            onChange={onChangeTransaction}
                            selectCard={SELECT_CARD}
                            daysThreshold={DAYS_THRESHOLD}
                            cost={costForCardTransaction}
                          />
                        </Flex>
                      )}
                  </Card>
                </Flex>
              </Flex>
            )}
            {showLienWaiverField && (
              <Flex width="full" justify="flex-start" direction="column">
                <Flex gap="lg" direction="column">
                  <Text as="h2" size="xl" weight="bold">
                    {STRINGS.LIEN_WAIVER_TITLE}
                  </Text>
                  {formValues.lienWaivers?.map((lienWaiver) => (
                    <BillLienWaiverField
                      customer={lienWaiver.customer?.url || ""}
                      key={lienWaiver.id}
                      value={parseLienWaiverTemplate(lienWaiver)}
                      onChange={curriedOnChangeLienWaiverTemplate(
                        lienWaiver.id
                      )}
                      recipientEmail={billPayment?.vendor?.email || undefined}
                      vendor={billPayment.vendor}
                      paymentAmount={Number(lienWaiver?.paymentAmount || "0")}
                      billPaymentId={billPayment?.id}
                      billLienWaiver={lienWaiver as LienWaiver}
                      onRequestUpdate={
                        isCancelledVoidedOrFailed
                          ? undefined
                          : onChangeTransaction
                      }
                      isReadOnly={isCancelledVoidedOrFailed}
                      billInternalVersion={billInternalVersion}
                      billId={lienWaiver?.billId || undefined}
                      billDocNumber={lienWaiver?.billDocNumber || ""}
                      isProcessPrerequisite={
                        !!lienWaiver?.isProcessPrerequisite
                      }
                    />
                  ))}
                </Flex>
              </Flex>
            )}
            {!isLoadingInitialData && billPayment && (
              <Flex width="full" justify="flex-start" direction="column">
                <Flex gap="lg" direction="column">
                  <Text as="h2" size="xl" weight="bold">
                    {STRINGS.ASSOCIATED_TRANSACTION_TITLE}
                  </Text>
                  <LinkedTransactionsTable
                    bills={billPayment?.bills}
                    appliedVendorCredits={billPayment?.appliedVendorCredits}
                    loading={isBillPaymentLoading}
                  />
                </Flex>
              </Flex>
            )}
          </Flex>
          {commentsSelector && !isLoadingInitialData && (
            <Comments
              selector={commentsSelector}
              onAddComment={canPayBill ? onAddComment : undefined}
            />
          )}
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Flex grow justify="space-between">
          <Button
            disabled={isLoadingInitialData || isLoadingAction}
            size="lg"
            color="neutral"
            onClick={() => onClose()}
            variant="text"
          >
            {STRINGS.CLOSE_TITLE}
          </Button>
          <Flex gap="xl">
            {billPayment?.paymentMethod === PAYMENT_METHOD.PRINT_CHECK &&
              isPaid && (
                <Button
                  size="lg"
                  disabled={
                    isLoadingInitialData || isLoadingAction || !canPayBill
                  }
                  color="info"
                  data-testid="view-printed-check-payment"
                  onClick={onDownloadPrintedCheck}
                >
                  {STRINGS.VIEW_PRINTED_CHECK}
                </Button>
              )}
            {(canCancelPayment || canVoidPayment) && billPayment && (
              <Tooltip message={cancelOrVoidButtonDisabled} placement="left">
                <Button
                  size="lg"
                  disabled={!!cancelOrVoidButtonDisabled}
                  color="error"
                  variant="ghost"
                  data-testid="cancel-or-void-payment"
                  onClick={() => onCancelOrVoidPayment(billPayment.id)}
                >
                  {canCancelPayment ? STRINGS.CANCEL_TITLE : STRINGS.VOID_TITLE}{" "}
                  {STRINGS.PAYMENT_TITLE}
                </Button>
              </Tooltip>
            )}
            {canPayNowScheduledPayment && billPayment && (
              <Tooltip placement="left">
                <Button
                  size="lg"
                  data-testid="pay-now-scheduled-payment"
                  onClick={() => onPayNowScheduledPayment(billPayment.id)}
                >
                  {STRINGS.PAY_NOW_TITLE}
                </Button>
              </Tooltip>
            )}
          </Flex>
        </Flex>
      </DialogFooter>
    </>
  );
};
